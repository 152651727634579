module.exports = [
  {
    id: 1,
    href: "https://github.com/maheralsamman/travelogger",
    img: "travellogger-screenshot.jpg",
    title: "Travelogger",
    description:
      "React & Redux, SwiperJS, NodeJS & ExpressJS, MongoDB & Mongoose",
  },
  {
    id: 2,
    href: "https://unifi-your-life.netlify.app/",
    img: "Ubiquiti-screenshot.jpg",
    title: "Ubiquiti",
    description: "React & TypeScript",
  },
  {
    id: 3,
    href: "https://github.com/maheralsamman/Github-Getter",
    img: "github-getter.jpg",
    title: "Github Getter",
    description: "React, NodeJS & ExpressJS",
  },
  {
    id: 4,
    href: "https://github.com/maheralsamman/react-redux-toolkit-todoApp",
    img: "todoList.jpg",
    title: "Todo App",
    description: "React & Redux Toolkit",
  },
  {
    id: 5,
    href: "https://github.com/maheralsamman/Muzikawi",
    img: "muzikawy.jpg",
    title: "Muzikawi",
    description: "React & styled components",
  },
  {
    id: 6,
    href: "/",
    img: "Kladersbyte.jpg",
    title: "Klädersbyte",
    description: "React, NodeJS, ExpressJS & Firebase",
  },
  {
    id: 7,
    href: "https://github.com/maheralsamman/Puppies-React-App",
    img: "Puppies-typeScript-React.jpg",
    title: "Puppies App",
    description: "React, TypeScript, NodeJS, & ExpressJS",
  },
  {
    id: 8,
    href: "https://github.com/maheralsamman/svaltekit-moviedb",
    img: "movieDB.jpg",
    title: "Movie Database",
    description: "Svelte, SvelteKit, Javascript and CSS ",
  },
  {
    id: 9,
    href: "https://englishlanguarium.netlify.app/",
    img: "english-teacher.jpg",
    title: "English Teacher",
    description: "NextJS, Tailwind, framer motion and Javascript",
  },
];
