import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import "./Resume.css";
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
const pageStyle = {
  width: "100 %",
  height: "100vh",
  backgroundColor: "#2c2d2f",
};
const Resume = () => {
  return (
    <motion.div
      style={pageStyle}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.5 }}
    >
      {/* <!--Resume Section Start--> */}
      <section id="resume" className="resume-section pt-page">
        <motion.div
          className="section-container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="page-heading"
            initial={{ y: -250 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.7 }}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faIdCard} />
            </span>
            <h2>My Resume.</h2>
          </motion.div>

          {/* <!--Education & Experience Row Start--> */}
          <motion.div className="row mb-4" variants={item}>
            <div className="col-lg-6">
              <div className="subheading">
                <h3>Education</h3>
              </div>

              <ul className="education">
                {/* <!--Education Item--> */}
                <li>
                  <span className="line-left"></span>
                  <div className="content">
                    <h4>Computer technician</h4>
                    <h5>Damascus University</h5>
                    <p className="info">
                      Diploma training - software and hardware maintenance
                    </p>
                  </div>
                  <span className="year">
                    <span className="to">(Sep) 2011</span>
                    <span className="from">(June) 2013</span>
                  </span>
                </li>

                {/* <!--Education Item--> */}
                <li>
                  <span className="line-left"></span>
                  <div className="content">
                    <h4>FrontEnd Developer</h4>
                    <h5>Nackademin</h5>
                    <p className="info">
                      During these two years, I have discovered that I really
                      like working with projects. It was great fun to be
                      involved in building, performing, and seeing the results
                      of what I have done. HTML- CSS- JAVASCRIPT- REACT- PHP-
                      SCSS- GIT- FIREBASE
                    </p>
                  </div>
                  <span className="year">
                    <span className="to">(Aug) 2019</span>
                    <span className="from">(June) 2021</span>
                  </span>
                </li>

                {/* <!--Education Item--> */}
                <li>
                  <span className="line-left"></span>
                  <div className="content">
                    <h4>Full Stack JavaScript</h4>
                    <h5>School of Applied Technology &lt;\salt&gt;</h5>
                    <p className="info">
                      Intensive three month training program for full stack web
                      development with a focus on TDD, mob programming, and
                      applied learning.
                    </p>
                  </div>
                  <span className="year">
                    <span className="to">(May) 2022</span>
                    <span className="from">(Aug) 2022</span>
                  </span>
                </li>
              </ul>
            </div>

            {/* <!--Experience Column Start--> */}
            <div className="col-lg-6">
              <div className="subheading">
                <h3>Work Experience</h3>
              </div>
              <ul className="experience">
                {/* <!--Experience Item--> */}
                <li>
                  <span className="line-left"></span>
                  <div className="content">
                    <h4>Computer technician</h4>
                    <h5>Dustin AB</h5>
                    <p className="info">
                      Perform product-related services on orders, administrative
                      work related to configuration services, Improvement work
                      to optimize services and orders, involvement in internal
                      and external management teams to ensure delivery quality
                      for the department.
                    </p>
                  </div>
                  <span className="year">
                    <span className="to">(May) 2017</span>
                    <span className="from">(May) 2022</span>
                  </span>
                </li>

                {/* <!--Experience Item--> */}
                <li>
                  <span className="line-left"></span>
                  <div className="content">
                    <h4>FrontEnd internship</h4>
                    <h5>Redmind</h5>
                    <p className="info">
                      Create platform with React and build the functions needed
                      and ensure that the entire platform is responsive
                      according to their design and wireframes.
                    </p>
                  </div>
                  <span className="year">
                    <span className="to">(Aug) 2020</span>
                    <span className="from">(March) 2021</span>
                  </span>
                </li>
            {/* <!--Experience Column End--> */}

                {/* <!--Experience Item--> */}
                <li>
                  <span className="line-left"></span>
                  <div className="content">
                    <h4>FrontEnd developer</h4>
                    <h5>Carnegie</h5>
                    <p className="info">
                    I work as a Frontend developer in the team responsible for credit system. 
                    Since the whole team work with backend and using .Net core and EF core, 
                    I got the chance to learn .Net core and EF core.
                    </p>
                  </div>
                  <span className="year">
                    <span className="to">(Jan) 2023</span>
                    <span className="from"></span>
                  </span>
                </li>
            {/* <!--Experience Column End--> */}

              </ul>
            </div>
          </motion.div>
          {/* <!--Education & Experience Row End--> */}
        </motion.div>
      </section>
      {/* <!--Resume Section End--> */}
    </motion.div>
  );
};
export default Resume;
