import { motion } from "framer-motion";
import "./Portfolio.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import portFolioList from "./portFolioList";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const pageStyle = {
  width: "100 %",
  height: "100vh",
  backgroundColor: "#2c2d2f",
};
const Portfolio = () => {
  return (
    <motion.div
      style={pageStyle}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.5 }}
    >
      {/* <<!--Porfolio Section Start--> */}
      <section id="portfolio" className="portfolio-section pt-page pb-1">
        <motion.div
          className="section-container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {/* <!--Page Heading--> */}
          <motion.div
            className="page-heading"
            initial={{ y: -250 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.7 }}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faBriefcase} />
            </span>
            <h2>Portfolio.</h2>
          </motion.div>

          <motion.div className="row" variants={item}>
            {/* <!--Portfolio Filter--> */}
            <div className="col-md-12">
              <div className="subheading">
                <h3>My Projects</h3>
              </div>
            </div>
          </motion.div>

          {/* <!--Portfolio Items--> */}
          <div className="row portfolio-items mb-5 justify-content-center ">

            {/* <!--Map Portfolio Items--> */}
            {portFolioList.map((portFolio) => (
              <motion.div
                key={portFolio.id}
                className="item col-lg-6 col-sm-10"
                variants={item}
              >
                <a
                  className="image-link"
                  href={portFolio.href}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <figure>
                    <img
                      src={require(`./../../assets/${portFolio.img}`).default}
                      alt="website cover"
                      className="website-img"
                    />
                    <figcaption>
                      <h4>{portFolio.title}</h4>
                      <p>{portFolio.description}</p>
                    </figcaption>
                  </figure>
                </a>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>
      {/* <!--Porfolio Section End--> */}
    </motion.div>
  );
};
export default Portfolio;
